

import { Card, Col, Collapse, Row } from 'antd';
import React from 'react';
const { Panel } = Collapse;

const OurVesion = () => {
  const onChange = (key) => {
    console.log(key);
  };
  return (
    <>
        <div id='faq' className="container our-vesion">
          <div className="heading">
          <span>OUR VESION</span>
            <h2 className=""><span>Our Vision for Empowering Decision-Makers, Driving Innovation.</span> </h2>
          
          </div>
          <Row gutter={24}>
            <Col xs={{ span: 24 }} >
              <Card  bordered={false} >


                <Collapse bordered={false}  onChange={onChange} >
                  <Panel className='font16' header="Empowering Decision-Makers" key="1" >
                    <p>We envision a world where decision-makers are empowered with the right information at the right time. By providing a dynamic platform for market research and online surveys, Poll Sphere aims to equip businesses with the tools they need to navigate complexity and make informed decisions.</p>
                  </Panel>
                  <Panel className='font16' header="Driving Innovation Through Data" key="2">
                    <p>Innovation thrives on understanding, and understanding is born from data. Poll Sphere envisions fostering a culture of innovation by facilitating access to rich, relevant, and real-time data. We aim to be the driving force behind groundbreaking ideas and transformative solutions.</p>
                  </Panel>
                  <Panel className='font16' header="Building a Collaborative Community" key="3">
                    <p>Knowledge grows exponentially when shared. Poll Sphere strives to build a vibrant and collaborative community where researchers, businesses, and thought leaders converge. We envision a space where ideas are exchanged, experiences are shared, and collective intelligence propels us all forward.</p>
                  </Panel>
                  <Panel className='font16' header="Championing Ethical Research Practices" key="4">
                  <p>As stewards of data, we recognize the importance of ethical research practices. Our vision includes setting industry standards for data privacy, transparency, and integrity. We are committed to ensuring that the insights generated on our platform are not only powerful but also ethically sourced and utilized.</p>
                </Panel>
                 
                </Collapse>
                {/* </div> */}
              </Card>
            


            </Col>
          


          </Row>
        </div>
    

    </>

  );
};
export default OurVesion;