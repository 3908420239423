import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
export default class Solutions extends Component {
  render() {
    const settings = {
      infinite: false,
      dots: false,
      arrows: true,
      slidesToShow: 3.3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2.3,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1.2,
          },
        },
      ],
    };
    return (
      <div>
        <Slider {...settings}>
          <div class="service-item">
            <div class="service">
              <div class="img">
                <img src="/images/home/cawi1.jpg" alt="" />
              </div>
              <div class="service-text-wrapper">
                <NavLink to="/computer-assisted-web-interviewing/">
                  <div class="service-text">
                    <h2>CAWI </h2>
                    <p>
                      CAWI streamlines data collection by utilizing digital
                      platforms ...
                    </p>
                    <div class="button">
                      <span>
                        {" "}
                        <i class="fa-solid fa-file-lines"></i>{" "}
                        <span>Read More</span>
                      </span>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
          <div class="service-item">
            <div class="service">
              <div class="img">
                <img src="/images/home/phone-to-web.jpg" alt="" />
              </div>
              <div class="service-text-wrapper">
                <NavLink to="/phone-to-web/">
                  <div class="service-text">
                    <h2>Phone To Web </h2>
                    <p>
                      Phone-to-web surveys seamlessly bridge traditional
                      telephone interviewing ...
                    </p>
                    <div class="button">
                      <span>
                        {" "}
                        <i class="fa-solid fa-file-lines"></i>{" "}
                        <span>Read More</span>
                      </span>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
          <div class="service-item">
            <div class="service">
              <div class="img">
                <img src="/images/home/cat1.jpg" alt="" />
              </div>
              <div class="service-text-wrapper">
                <NavLink to="/cati/">
                  <div class="service-text">
                    <h2>CATI </h2>
                    <p>
                      CATI optimizes survey administration by integrating
                      technology to ...
                    </p>
                    <div class="button">
                      <span>
                        {" "}
                        <i class="fa-solid fa-file-lines"></i>{" "}
                        <span>Read More</span>
                      </span>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
          <div class="service-item">
            <div class="service">
              <div class="img">
                <img src="/images/home/idi.jpg" alt="" />
              </div>
              <div class="service-text-wrapper">
                <NavLink to="/in-depth-interview/">
                  <div class="service-text">
                    <h2>IDI </h2>
                    <p>
                      IDI is a qualitative research method where a researcher
                      conducts ...
                    </p>
                    <div class="button">
                      <span>
                        {" "}
                        <i class="fa-solid fa-file-lines"></i>{" "}
                        <span>Read More</span>
                      </span>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
          <div class="service-item">
            <div class="service">
              <div class="img">
                <img src="/images/home/programming1.jpg" alt="" />
              </div>
              <div class="service-text-wrapper">
                <NavLink to="/programming-and-data-processing/">
                  <div class="service-text">
                    <h2>Programming and Data Processing </h2>
                    <p>
                      Programming and data processing involve designing,
                      writing, and...
                    </p>
                    <div class="button">
                      <span>
                        {" "}
                        <i class="fa-solid fa-file-lines"></i>{" "}
                        <span>Read More</span>
                      </span>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}
