import { Col, Row } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";

const Segment = () => {
const [tab1 , setTab1] =useState ()
const [tab2 , setTab2] =useState ()
const [tab3 , setTab3] =useState ()
const [tab4 , setTab4] =useState ()

const tabbtn1 =()=>{
setTab1(true)
setTab2(false)
setTab3(false)
setTab4(false)

}
const tabbtn2 =()=>{
  setTab1(false)
  setTab2(true)
  setTab3(false)
  setTab4(false)
  
  }
  const tabbtn3 =()=>{
    setTab1(false)
    setTab2(false)
    setTab3(true)
    setTab4(false)
    
    }
    const tabbtn4 =()=>{
      setTab1(false)
      setTab2(false)
      setTab3(false)
      setTab4(true)
      
      }


useEffect(()=>{
  tabbtn1()

},[])



  return (
    <div className="business-segment  py-50">
      <div className="container">
        <div className="segment-heading pb-5 mb-4 flex-centet-between">
          <h2 className="mb-0 heading"> <span>Poll Sphere</span> at a glance</h2>
         <div className="tab-wrapper">
         <ul className="nav nav-tabs flex-centet-start">
            <li className="nav-item">
            <span onClick={()=>tabbtn1()} className={tab1 ? "nav-link active":"nav-link "}>
            Our commitment
            </span>
             
            </li>
            <li className="nav-item">
            <span onClick={()=>tabbtn2()} className={tab2 ? "nav-link active":"nav-link "}>
            Our road-map
            </span>
            </li>
            <li className="nav-item">
            <span onClick={()=>tabbtn3()} className={tab3 ? "nav-link active":"nav-link "}>
            Our context
            </span>
            </li>
            <li className="nav-item">
            <span onClick={()=>tabbtn4()} className={tab4 ? "nav-link active":"nav-link "}>
            Our stories
            </span>
            </li>
          </ul>
         </div>
          
        </div>

        <div className="tab-content">
          <div className={tab1 ? "tab-pane container active":"tab-pane container "} >
            <div className="items">
            <Row gutter={24} align="middle">
            <Col md={10} xs={24}>
            <div className="segment-text">
                      <h2 className="heading">Committed to making a positive difference</h2>
                      <p className="px-2">
                       
We adopt a perspective focused on the future, cultivating lasting connections that result in shared development and sustainable results.
                      </p>
                    </div>
            </Col>
            <Col md={2} xs={24}>
            
            </Col>
            <Col md={12} xs={24}>
            <div className="img">
            <img src="/images/home/commitment.jpg" alt="business-seg" />
          </div>
            </Col>
            
            
            </Row>
           
            </div>
          </div>

        <div className={tab2 ? "tab-pane container active":"tab-pane container "} >
        <div className="items">
        <div className="items">
        <Row gutter={24} align="middle">
        <Col md={10} xs={24}>
        <div className="segment-text">
                    <h2 className="heading">Foster and distribute the availability of knowledge.</h2>
                    <p className="px-2">
                    Through collaboration, we leverage knowledge and expertise to enhance both our own growth and the betterment of our world.
                    </p>
                </div>
        </Col>
        <Col md={2} xs={24}>
        
        </Col>
        <Col md={12} xs={24}>
        <div className="img">
        <img src="/images/home/rode-map.jpg" alt="business-seg" />
        </div>
        </Col>
        
        
        </Row>
        
        </div>
        </div>
        </div>
        <div className={tab3 ? "tab-pane container active":"tab-pane container "} >
        <div className="items">
        <Row gutter={24} align="middle">
        <Col md={10} xs={24}>
        <div className="segment-text">
                <h2 className="heading">Choose connections, consume content: your personalized engagement</h2>
                <p className="px-2">
                Empower people with more autonomy in engaging globally
                </p>
                </div>
        </Col>
        <Col md={2} xs={24}>
        
        </Col>
        <Col md={12} xs={24}>
        <div className="img">
        <img src="/images/home/consume.jpg" alt="business-seg" />
    </div>
        </Col>
        
        
        </Row>
        
        </div>
        </div>
        <div className={tab4 ? "tab-pane container active":"tab-pane container "} >
        <div className="items">
        <Row gutter={24} align="middle">
        <Col md={10} xs={24}>
        <div className="segment-text">
                <h2>  Our stories</h2>
                <p className="px-2">
                At Poll Sphere, 'Our Stories' unfold, weaving data into narratives. Each insight is a brushstroke, painting a vivid tapestry of market dynamics. Join us on a journey where information becomes an art form, telling stories that shape the future.
                </p>
                </div>
        </Col>
        <Col md={2} xs={24}>
        
        </Col>
        <Col md={12} xs={24}>
        <div className="img">
        <img src="/images/home/stories.jpg" alt="business-seg" />
    </div>
        </Col>
        
        
        </Row>
        
        </div>
        </div>
        
        </div>
      </div>
    </div>
  );
};

export default Segment;
