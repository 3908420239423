import React, { useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Col, Row, Form, Input, Select } from "antd";
import "./common.css";
import PageBanner from "../components/PageBanner";
import { FaInstagram } from "react-icons/fa";
import { BiLogoFacebook } from "react-icons/bi";
import { FaXTwitter, FaLinkedinIn } from "react-icons/fa6";

const { Option } = Select;

const { TextArea } = Input;

const Contact = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  const currentURL = window.location.href;
const navigate = useNavigate();
useEffect(() => {
  window.scrollTo(0, 0);
}, [pathname]);
useEffect(() => {
  if(!location.pathname.endsWith('/')){
    let NewPath = location.pathname+"/".toLowerCase();
    if (location.pathname !== NewPath) {
        navigate(NewPath);
    }
    }
}, [location, navigate]);
  const bannerData = {
    image: "/images/contact-banner.jpg",
    heading: "Get in Touch",
    para: "Whether you have a query about our services, need assistance with a specific survey, or want to learn more about how we can tailor our solutions to suit your unique needs, our team is here to help. Reach out to us using the contact information below, and we'll ensure a prompt and informative response.",
  };
  return (
    <div className="page-content">
      <Helmet>
        <title>Contact Us | Poll Sphere</title>
        <meta
          name="description"
          content="Reach out to Poll Sphere for all your market research needs. Whether you're seeking tailored survey solutions, have questions about our services, or need expert advice, our dedicated team is here to assist you. Connect with us today to start transforming data into decisions"
        />
        <link rel="canonical" href="https://www.pollsphere.com/contact-us/" />
      </Helmet>
      <PageBanner {...bannerData} />

      <div className="container py-50">
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <div className="contact-page-form-wrapper">
              <Form id="survey_submit_form" layout="vertical">
                <Form.Item name="firstName">
                  <Input placeholder="First Name" />
                </Form.Item>
                <Form.Item name="lastName">
                  <Input placeholder="Last Name" />
                </Form.Item>
                <Form.Item name="email">
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                  name="gender"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select placeholder="Client " allowClear>
                    <Option value="male">Market Research Company</Option>
                    <Option value="female">Affiliate Partner</Option>
                    <Option value="other">Publisher</Option>
                  </Select>
                </Form.Item>
                <Form.Item name="company">
                  <Input placeholder="Company Name" />
                </Form.Item>
                <Form.Item name="Message">
                  <TextArea rows={4} placeholder="Write your message" />
                </Form.Item>

                <button className="btn form-btn" htmlType="submit">
                  Submit
                </button>
              </Form>
            </div>
          </Col>
          <Col xs={24} md={2}></Col>
          <Col xs={24} md={10}>
            <div className="wrapper contact-info-wrapper">
              <h2 className=" section-heading">Contact Details</h2>

              <h3>Email Us</h3>
              <NavLink
                to="mailto:info@pollsphere.com"
                target="_blank"
                rel="noopener"
              >
                info@pollsphere.com
              </NavLink>

              <h3>Call Us</h3>
              <p>
                Connect with our customer support team or speak directly to a
                market research expert{" "}
              </p>
              <NavLink to="tel:+9112345678"> +91 1234567809</NavLink>

              <h3>Visit Us</h3>
              <p>
                Prefer a face-to-face meeting? Our office is open to clients and
                visitors
              </p>
              <p>Business Bay, Dubai</p>

              <h3>Stay Connected</h3>
              <p>
                Follow us on our social media channels to stay updated with the
                latest trends, tips, and news from the world of market research
                and surveys.
              </p>
              <ul className="flex-centet-start social ">
                <li>
                  <NavLink to="https://www.facebook.com/pollspheresurvey/">
                    <BiLogoFacebook />
                  </NavLink>
                </li>
                <li>
                  <NavLink to="https://www.linkedin.com/company/poll-sphere/">
                    <FaLinkedinIn />
                  </NavLink>
                </li>
                <li>
                  <NavLink to="https://www.instagram.com/pollsphere/">
                    <FaInstagram />
                  </NavLink>
                </li>
                <li>
                  <NavLink to="https://twitter.com/PollSphere">
                    <FaXTwitter />
                  </NavLink>
                </li>
              </ul>

              <h3>Feedback and Suggestions</h3>

              <p>
                At Poll Sphere, we believe that effective communication is the
                cornerstone of success. We're looking forward to hearing from
                you and are ready to assist with any inquiries or requirements
                you may have. Together, let's unlock the power of data and
                insights to drive your business forward.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Contact;
