import React from "react";
import { Col, Row } from "antd";
import { NavLink } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";
import { BiLogoFacebook } from "react-icons/bi";
import {
  FaXTwitter,
  FaLinkedinIn,
  FaPhone,
  FaRegEnvelope,
  FaWhatsapp,
} from "react-icons/fa6";

const Footer = () => {

  return (
    <footer>
      <section className=" bg-dark border-bottom py-5">
        <div className="punchline-wrapper  py-50">
          <Row gutter={24}>
            <Col md={12} xs={24}>
              <div className="punchline-left">
                <div className="text">
                  <h2 className=" h1 text-white fw-5">
                    Begin your metamorphosis here.
                  </h2>
                  <p className="text-muted">Envision a new destiny.</p>
                  <NavLink className="btn" to="mailto:rjyadavweb@gmail.com">
                    <span>Connect to us </span>
                  </NavLink>
                </div>
              </div>
            </Col>
            <Col md={12} xs={24}>
              <div className="punchline-right mt-4 mt-md-0 ps-md-5">
                <div>
                  <div className="punchline-links">
                    <span className="text-white ">
                      <strong>Services</strong>{" "}
                    </span>
                    <ul>
                      <li>
                        <NavLink to="/in-depth-interview/">IDI</NavLink>
                      </li>
                      <li>
                        <NavLink to="/computer-assisted-web-interviewing/">CAWI</NavLink>
                      </li>
                      <li>
                        <NavLink to="/cati/">CATI</NavLink>
                      </li>
                      <li>
                        <NavLink to="/phone-to-web/">Phone to Web</NavLink>
                      </li>
                      <li>
                        <NavLink to="/programming-and-data-processing/">
                          Data Processing
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <div className="copyright">
        <Row align={"middle"}>
          <Col md={8} sm={12} xs={24}>
            <NavLink to="/" className="footer-logo">
              <img src="/logo/Poll_Sphere_Logo-white.png" alt="" />
            </NavLink>
          </Col>
          <Col md={8} sm={12} xs={24}>
            <ul className="flex-centet-start social ">
              <li>
                <NavLink to="https://www.facebook.com/pollspheresurvey/">
                  <BiLogoFacebook />
                </NavLink>
              </li>
              <li>
                <NavLink to="https://www.linkedin.com/company/poll-sphere/">
                  <FaLinkedinIn />
                </NavLink>
              </li>
              <li>
                <NavLink to="https://www.instagram.com/pollsphere/">
                  <FaInstagram />
                </NavLink>
              </li>
              <li>
                <NavLink to="https://twitter.com/PollSphere">
                  <FaXTwitter />
                </NavLink>
              </li>
            </ul>
          </Col>
          <Col md={8} sm={12} xs={24}>
            <p className="mb-0">©2023-24 Poll Sphere</p>
          </Col>
        </Row>
      </div>

{
      // <div className="social-sticky-wrapper">
       

      //   <NavLink
      //     className="borderd"
      //     to="mailto:info@pollsphere.com"
      //     target="_blank"
      //     rel="noopener"
      //   >
      //     <FaRegEnvelope />
      //   </NavLink>
      //   <NavLink to="/">
      //     {" "}
      //     <FaPhone />
      //   </NavLink>
      // </div>
}

    </footer>
  );
};

export default Footer;
