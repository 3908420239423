
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './slick.css';
import './slick-theme.css'
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import CAWI from "./pages/CAWI";
import CATI from "./pages/Cati";
import PhonToWeb from "./pages/PhoneToWeb";
import IDI from "./pages/IDI";
import Programming from "./pages/Programming";
import About from "./pages/About";
import FAQ from "./pages/FAQ";
import NotFound from "./components/404";

function App() {

  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/computer-assisted-web-interviewing/" element={<CAWI />} />
        <Route path="/phone-to-web/" element={<PhonToWeb />} />
        <Route path="/cati/" element={<CATI />} />
        <Route path="/in-depth-interview/" element={<IDI />} />
        <Route path="/programming-and-data-processing/" element={<Programming />} />
        <Route path="/about-us/" element={<About />} />
        <Route path="/faq/" element={<FAQ />} />
        <Route path="/contact-us/" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
     <Footer />
    </Router>
  );
}

export default App;
