import React, { useState, useEffect } from "react";
import PageBanner from "../components/PageBanner";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Col, Row } from "antd";
import { FaTimes } from "react-icons/fa";

const CAWI = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  const currentURL = window.location.href;
const navigate = useNavigate();
useEffect(() => {
  window.scrollTo(0, 0);
}, [pathname]);
useEffect(() => {
  if(!location.pathname.endsWith('/')){
    let NewPath = location.pathname+"/".toLowerCase();
    if (location.pathname !== NewPath) {
        navigate(NewPath);
    }
    }
}, [location, navigate]);
  const [toggle, setToggle] = useState();
  const [toggle1, setToggle1] = useState();

  const handletoggle = () => {
    setToggle(!toggle);
  };
  const handletoggle1 = () => {
    setToggle1(!toggle1);
  };

  const cancletoggle = () => {
    setToggle(false);
    setToggle1(false);
  };

  const bannerData = {
    image: "/images/services/cawi-banner.jpg",
    heading: "Computer-assisted web interviewing",
    para: "Explore the world of market research and online surveys at Poll Sphere, where we harness the power of computer-assisted web interviewing to unlock valuable consumer insights",
  };
  return (
    <div className="page-content">
      <Helmet>
        <title>Computer-Assisted Web Interviewing | Poll Sphere</title>
        <meta
          name="description"
          content="Have you heard of the CAWI method for conducting online surveys? It's a great way to make your surveys more effective."
        />
        <link rel="canonical" href="https://www.pollsphere.com/computer-assisted-web-interviewing/" />
      </Helmet>
      <PageBanner {...bannerData} />

      <section className="py-50">
        <div className="container">
          <Row align="middle" className="content-main-wrapper">
            <Col md={12}>
              <div className="img-wrapper">
                <img src="/images/services/Cawi.jpg" alt="" />
              </div>
            </Col>
            <Col md={12}>
              <div className="content-wrapper px-50">
                <h2> What is computer-assisted web interviewing (CAWI)</h2>
                <p>
                  We also know CAWI as Computer-assisted web interviewing.
                  Computer-assisted web interviewing is a Internet survey
                  technique in which the interviewee follows a script provided
                  on a website. The web-based interviewing program used to
                  create the questionnaire supports multimedia elements,
                  including images, audio, video clips, and links to external
                  web pages. When you are doing market research and collecting
                  data with the CAWI method the questionnaire design is super
                  important. If you want people to respond, the questionnaire
                  has to be top-notch.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section>
        <div className="container-wrapper how-works ">
          <div className="img-wrapper">
            <img src="/images/services/how-work.jpg" alt="" />
          </div>
          <div className="text-wrapper">
            <div className="container">
              <Row align="middle">
                <Col md={12}>
                  <div className="content-wrapper">
                    <h2> How Does CAWI Work?</h2>
                    <p>
                      {" "}
                      <strong> Survey Design : </strong>
                      The journey begins with crafting a well-structured survey
                      instrument. Researchers create questions, response
                      options, and design the survey flow, ensuring that it
                      aligns with the research objectives.
                    </p>

                    <button onClick={handletoggle} className="btn btn-white">
                      {" "}
                      Read More
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div
          className={
            toggle ? "hide-content-wrapper show" : "hide-content-wrapper "
          }
        >
          <div className="container ">
            <div className="hide-text">
              <span onClick={cancletoggle} className="cancle-btn">
                <FaTimes />{" "}
              </span>

              <h2> How Does CAWI Work?</h2>
              <p>
                {" "}
                <strong>Survey Design : </strong>
                The journey begins with crafting a well-structured survey
                instrument. Researchers create questions, response options, and
                design the survey flow, ensuring that it aligns with the
                research objectives.
              </p>
              <p>
                {" "}
                <strong>Online Deployment : </strong>
                Once the survey is ready, it's uploaded to an online platform.
                Respondents receive invitations via email, social media, or
                other online channels, guiding them to the survey.
              </p>
              <p>
                {" "}
                <strong>Data Collection : </strong>
                Respondents access the survey through their web browsers,
                providing responses to the questions presented. CAWI systems are
                designed to be user-friendly, ensuring a smooth respondent
                experience.
              </p>
              <p>
                {" "}
                <strong>Real-time Data Capture : </strong>
                The journey begins with crafting a well-structured survey
                instrument. Researchers create questions, response options, and
                design the survey flow, ensuring that it aligns with the
                research objectives.
              </p>
              <p>
                {" "}
                <strong>Data Analysis : </strong>
                Researchers can access the collected data through secure online
                portals. Advanced analytical tools help uncover insights,
                patterns, and trends within the dataset.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-50">
        <div className="container-wrapper how-works ">
          <div className="img-wrapper">
            <img src="/images/services/benefits.jpg" alt="" />
          </div>
          <div className="text-wrapper">
            <div className="container">
              <Row align="middle">
                <Col md={12}>
                  <div className="content-wrapper">
                    <h2> What are the advantages of CAWI?</h2>
                    <p>
                      CAWI offers several advantages over other data collection
                      methods. Here are some of the key benefits:
                    </p>
                    <ul>
                      <li>
                        <strong>Fast data collection:</strong> Responses are
                        received and entered automatically, reducing processing
                        time.
                      </li>
                    </ul>

                    <button onClick={handletoggle1} className="btn btn-white">
                      {" "}
                      Read More
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div
          className={
            toggle1 ? "hide-content-wrapper show" : "hide-content-wrapper "
          }
        >
          <div className="container ">
            <div className="hide-text">
              <span onClick={cancletoggle} className="cancle-btn">
                <FaTimes />{" "}
              </span>

              <h2> What are the advantages of CAWI?</h2>
              <p>
                CAWI offers several advantages over other data collection
                methods. Here are some of the key benefits:
              </p>
              <ul>
                <li>
                  <strong>Fast data collection:</strong> Responses are received
                  and entered automatically, reducing processing time.
                </li>
                <li>
                  {" "}
                  Cost-effective: No printing, postage, or travel expenses
                  involved.{" "}
                </li>
                <li>
                  {" "}
                  Data quality: Skip logic prevents errors and inconsistencies,
                  and instant data validation ensures completeness.{" "}
                </li>
                <li>
                  {" "}
                  Flexibility: Design surveys with multimedia elements,
                  branching logic, and customized layouts.{" "}
                </li>
                <li>
                  {" "}
                  Real-time monitoring: Track participation rates and adjust
                  strategies as needed.{" "}
                </li>
                <li>
                  {" "}
                  Accessibility: Reaches geographically dispersed populations
                  and those with disabilities.{" "}
                </li>
                <li>
                  {" "}
                  Convenient: Complete surveys at their own pace and time.{" "}
                </li>
                <li>
                  {" "}
                  Anonymous: Provides greater comfort in sharing honest
                  opinions.{" "}
                </li>
                <li>
                  {" "}
                  Interactive: Engaging format with multimedia elements can be
                  more enjoyable.{" "}
                </li>
                <li>
                  {" "}
                  Clear instructions: Skip logic ensures they only see relevant
                  questions.{" "}
                </li>
                <li>
                  {" "}
                  Data privacy: Secure online platforms ensure responses are
                  confidential.{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
     
      <section className="py-50">
        <div className="container-wrapper how-works ">
          <div className="img-wrapper">
            <img src="/images/services/cawi4.jpg" alt="" />
          </div>
          <div className="text-wrapper">
            <div className="container">
              <Row align="middle">
                <Col md={12}>
                  <div className="content-wrapper">
                    <h2> How does CAWI differ from other survey methods?</h2>

                    <p>
                      CAWI, which stands for Computer-Assisted Web Interviewing,
                      differs from other survey methods such as face-to-face or
                      telephone interviews in that it is conducted online via
                      the internet. This method allows for a wider reach, since
                      it can be accessed by anyone with an internet connection,
                      and also provides the ability to incorporate multimedia
                      elements such as images, videos, and audio recordings into
                      the survey design.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </section>
      <section className="py-50">
        <div className="container-wrapper how-works ">
          <div className="img-wrapper">
            <img src="/images/services/cawi5.jpg" alt="" />
          </div>
          <div className="text-wrapper">
            <div className="container">
              <Row align="middle">
                <Col md={12}>
                  <div className="content-wrapper">
                    <h2>key components of a CAWI system</h2>

                    <p>
                      The key components of a CAWI system typically include a
                      web server to host the survey, a database to store the
                      survey data, a web-based survey software application to
                      create and administer the survey, and a participant
                      interface that allows respondents to access and complete
                      the survey online. Additional features may include data
                      analysis tools, email invitations and reminders,
                      respondent tracking and monitoring, and various reporting
                      options.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CAWI;
