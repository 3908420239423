import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import PageBanner from "../components/PageBanner";
import { Col, Row } from "antd";
import { FaTimes } from "react-icons/fa";

const PhonToWeb = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  const currentURL = window.location.href;
const navigate = useNavigate();
useEffect(() => {
  window.scrollTo(0, 0);
}, [pathname]);
useEffect(() => {
  if(!location.pathname.endsWith('/')){
    let NewPath = location.pathname+"/".toLowerCase();
    if (location.pathname !== NewPath) {
        navigate(NewPath);
    }
    }
}, [location, navigate]);

  const bannerData = {
    image: "/images/services/phone-to-web-banner.jpg",
    heading: "Phone To Web",
    para: "   In the field of market research, the shift from conventional telephone surveys to the dynamic world of online surveys has revolutionized the manner in which companies collect valuable insights.",
  };
  const [toggle, setToggle] = useState();
  const [toggle1, setToggle1] = useState();

  const handletoggle = () => {
    setToggle(!toggle);
  };
  const handletoggle1 = () => {
    setToggle1(!toggle1);
  };

  const cancletoggle = () => {
    setToggle(false);
    setToggle1(false);
  };
  return (
    <div className="page-content">
      <Helmet>
        <title>
        Phone To Web Survey | Poll Sphere
        </title>
        <meta
          name="description"
          content="Take online surveys on any device with phone to web surveys. Boost survey response rates by designing mobile-compatible surveys."
        />
        <link rel="canonical" href="https://www.pollsphere.com/phone-to-web/" />
      </Helmet>
      <PageBanner {...bannerData} />

      <section className="py-50">
        <div className="container">
          <Row align="middle" className="content-main-wrapper">
            <Col md={12}>
              <div className="img-wrapper">
                <img src="/images/services/phone-interview.jpg" alt="" />
              </div>
            </Col>
            <Col md={12}>
              <div className="content-wrapper px-50">
                <h2> What is a Phone to Web survey?</h2>
                <p>
                  A Phone to Web survey is a type of survey where the respondent
                  is first contacted by phone and asked to participate in a
                  survey. If the respondent agrees, they are then directed to a
                  website where they can complete the survey online. This method
                  is used to reach a wider audience and has the advantage of
                  being able to collect data quickly and efficiently. It is also
                  a cost-effective way to conduct surveys as it eliminates the
                  need for paper surveys and reduces the costs associated with
                  phone interviews.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section>
        <div className="container-wrapper how-works ">
          <div className="img-wrapper">
            <img src="/images/services/Planning.jpg" alt="" />
          </div>
          <div className="text-wrapper">
            <div className="container">
              <Row align="middle">
                <Col md={12}>
                  <div className="content-wrapper">
                    <h2> How does the Phone to Web survey process work?</h2>
                    <ul>
                      <li>
                        {" "}
                        <strong> Phone Interview: </strong>
                        The survey commences with a telephone interview carried
                        out by a live interviewer who poses questions to elicit
                        responses from the participant during the conversation.
                      </li>
                      <li>
                        {" "}
                        <strong>Transition to Web Survey: </strong>
                        Following the conclusion of the phone interview,
                        participants are informed of the opportunity to proceed
                        with the survey online. They are furnished with
                        guidelines on how to access the web-based survey.
                      </li>
                    </ul>

                    <button onClick={handletoggle} className="btn btn-white">
                      {" "}
                      Read More
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div
          className={
            toggle ? "hide-content-wrapper show" : "hide-content-wrapper "
          }
        >
          <div className="container ">
            <div className="hide-text">
              <span onClick={cancletoggle} className="cancle-btn">
                <FaTimes />{" "}
              </span>

              <h2> How does the Phone to Web survey process work?</h2>
              <ul>
                <li>
                  {" "}
                  <strong> Phone Interview: </strong>
                  The survey commences with a telephone interview carried out by
                  a live interviewer who poses questions to elicit responses
                  from the participant during the conversation.
                </li>
                <li>
                  {" "}
                  <strong>Transition to Web Survey: </strong>
                  Following the conclusion of the phone interview, participants
                  are informed of the opportunity to proceed with the survey
                  online. They are furnished with guidelines on how to access
                  the web-based survey.
                </li>
                <li>
                  {" "}
                  <strong>Web Survey Setup: </strong>
                  The online survey is established through the utilization of an
                  online survey platform, such as Qualtrics, SurveyMonkey, or a
                  customized proprietary system. The questions that were posed
                  during the phone interview are duplicated in the web-based
                  survey.
                </li>
                <li>
                  {" "}
                  <strong>Participant Authentication: </strong> In order to
                  maintain data integrity, participants might be asked to verify
                  their identity on the web survey platform. This can be
                  achieved through the use of distinct login credentials or
                  alternative verification techniques.
                </li>
                <li>
                  <strong>Online Survey Completion: </strong> Participants
                  access the web survey following the provided instructions and
                  proceed to answer the remaining questions online. They input
                  their responses directly into the online survey form.
                </li>

                <li>
                  <strong>Data Integration: </strong> Upon participants'
                  completion of the web survey, their responses are
                  automatically gathered and seamlessly integrated into the
                  survey dataset. This guarantees a smooth amalgamation of both
                  phone and web survey data.
                </li>

                <li>
                  {" "}
                  <strong> Data Analysis: </strong> Subsequently, the collected
                  data is prepared for analysis. Researchers can employ
                  statistical analysis software or other suitable tools to
                  scrutinize the merged dataset, extracting insights and
                  formulating conclusions from the accumulated responses.
                </li>
              </ul>
              <p>
                The Phone to Web survey process starts with contacting the
                respondent by phone and requesting their participation in the
                survey. If the respondent agrees, they are then given
                instructions on how to access the survey online. The
                instructions may include a website address or a unique code that
                allows the respondent to access the survey. Once the respondent
                has accessed the survey online, they can complete it at their
                own pace.
              </p>
              <p>
                The survey may include different types of questions, such as
                multiple-choice, open-ended, or rating scale questions. Once the
                respondent completes the survey, their responses are
                automatically recorded in a database, making it easy to analyze
                the data and generate reports. Overall, the Phone to Web survey
                process is an efficient and cost-effective way to gather data
                from a wide audience.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-50">
        <div className="container-wrapper how-works ">
          <div className="img-wrapper">
            <img src="/images/services/advantages.jpg" alt="" />
          </div>
          <div className="text-wrapper">
            <div className="container">
              <Row align="middle">
                <Col md={12}>
                  <div className="content-wrapper">
                    <h2> Advantages Of Phone to Web survey</h2>

                    <p>
                      Phone-to-web surveys offer several advantages over
                      traditional survey methods.
                    </p>
                    <ul>
                      <li>
                        you can reach more people, and a more diverse group of
                        people
                      </li>
                    </ul>

                    <button onClick={handletoggle1} className="btn btn-white">
                      {" "}
                      Read More
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div
          className={
            toggle1 ? "hide-content-wrapper show" : "hide-content-wrapper "
          }
        >
          <div className="container ">
            <div className="hide-text">
              <span onClick={cancletoggle} className="cancle-btn">
                <FaTimes />{" "}
              </span>

              <h2> Advantages Of Phone to Web survey</h2>

              <p>
                Phone-to-web surveys offer several advantages over traditional
                survey methods.
              </p>

              <ul>
                <li>
                  you can reach more people, and a more diverse group of people
                </li>
                <li>
                  {" "}
                  Anyone with internet access can take part in phone-to-web
                  surveys
                </li>
                <li>
                  {" "}
                  This survey is convenient and lets you stay anonymous while
                  taking surveys.
                </li>
                <li>
                  {" "}
                  With a phone-to-web survey, the cost of data collection can be
                  significantly reduced, as there is no need to pay for phone
                  lines or in-person interviewers.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PhonToWeb;
