import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { FaBarsStaggered, FaAngleRight, FaAngleDown } from "react-icons/fa6";
import { AiOutlinePlus } from "react-icons/ai";

const Header = () => {
  const [nav, setNav] = useState();
  const [tab, setTab] = useState();
  const [tab2, setTab2] = useState();
  const [tab3, setTab3] = useState();
  const navToggle = () => {
    setNav(!nav);
  };
  const tabToggle = () => {
    setTab(!tab);
    setTab2(false);
    setTab3(false);
  };
  const tabToggle2 = () => {
    setTab(false);
    setTab2(!tab2);
    setTab3(false);
  };

  const closeNav = () => {
    setTab(false);
    setTab2(false);
    setTab3(false);
    setNav(false);
  };

  return (
    <div>
      <header>
        <div className="header position-relative">
          <nav>
            <div className="nav-left">
              <div className="logo  ">
                <NavLink onClick={closeNav} to="/">
                  <img src="/logo/Poll_Sphere_Logo-04.png" alt="logo" />
                </NavLink>
              </div>
              <div className={nav ? "menu active" : "menu "}>
                <ul className="main-ul">
                  <li>
                    <span className="main-nav-name">
                      <span>What we do</span>{" "}
                      <FaAngleDown onClick={tabToggle} />
                    </span>

                    <div className={tab ? "drop open" : "drop"}>
                      <div className="drop-content-wrapper">
                        <ul className="main-page-link-wrapper">
                          <li className="overview">
                            <NavLink onClick={closeNav} to="/computer-assisted-web-interviewing/">
                              CAWI <FaAngleRight />{" "}
                            </NavLink>{" "}
                            <span className="plus-icon ">
                              <AiOutlinePlus />
                            </span>
                            <div className=" sub-drop-item">
                              <h3 className="h4">
                                Computer-assisted web interviewing.
                              </h3>
                              <p className="pb-4">
                                Explore the world of market research and online
                                surveys at Poll Sphere, where we harness the
                                power of computer-assisted web interviewing to
                                unlock valuable consumer insights...{" "}
                              </p>
                              <NavLink
                                onClick={closeNav}
                                to="/computer-assisted-web-interviewing/"
                                className="btn"
                              >
                                Read More
                              </NavLink>
                            </div>
                          </li>
                          <li className="overview">
                            <NavLink onClick={closeNav} to="/phone-to-web/">
                              Phone To Web <FaAngleRight />{" "}
                            </NavLink>{" "}
                            <span className="plus-icon ">
                              <AiOutlinePlus />
                            </span>
                            <div className=" sub-drop-item">
                              <h3 className="h4">Phone To Web</h3>
                              <p className="pb-4">
                                In the field of market research, the shift from
                                conventional telephone surveys to the dynamic
                                world of online surveys has revolutionized the
                                manner in which companies...{" "}
                              </p>
                              <NavLink
                                onClick={closeNav}
                                to="/phone-to-web/"
                                className="btn"
                              >
                                Read More
                              </NavLink>
                            </div>
                          </li>
                          <li className="overview">
                            <NavLink onClick={closeNav} to="/cati/">
                              CATI <FaAngleRight />{" "}
                            </NavLink>{" "}
                            <span className="plus-icon ">
                              <AiOutlinePlus />
                            </span>
                            <div className=" sub-drop-item">
                              <h3 className="h4">
                                Computer-Assisted Telephonic Interviews
                              </h3>
                              <p className="pb-4">
                                In the dynamic field of market research, staying
                                ahead is crucial for businesses aiming to grasp
                                consumer behaviors and trends. This article
                                explores the significance of...{" "}
                              </p>
                              <NavLink
                                onClick={closeNav}
                                to="/cati/"
                                className="btn"
                              >
                                Read More
                              </NavLink>
                            </div>
                          </li>
                          <li className="overview">
                            <NavLink onClick={closeNav} to="/in-depth-interview/">
                              IDI <FaAngleRight />{" "}
                            </NavLink>{" "}
                            <span className="plus-icon ">
                              <AiOutlinePlus />
                            </span>
                            <div className=" sub-drop-item">
                              <h3 className="h4">In-Depth Interview</h3>
                              <p className="pb-4">
                                Explore the world of market research and online
                                surveys with an illuminating in-depth interview
                                featuring Poll Sphere. Uncover insights and
                                strategies for informed decision...{" "}
                              </p>
                              <NavLink
                                onClick={closeNav}
                                to="/in-depth-interview/"
                                className="btn"
                              >
                                Read More
                              </NavLink>
                            </div>
                          </li>
                          <li className="overview">
                            <NavLink
                              onClick={closeNav}
                              to="/programming-and-data-processing/"
                            >
                              Programming & Data Processing <FaAngleRight />{" "}
                            </NavLink>{" "}
                            <span className="plus-icon ">
                              <AiOutlinePlus />
                            </span>
                            <div className=" sub-drop-item">
                              <h3 className="h4">
                                Programming & Data Processing
                              </h3>
                              <p className="pb-4">
                                In the fast-paced world of business and market
                                research, the ability to collect, analyze, and
                                derive actionable insights from data is
                                paramount. At Poll Sphere, we understand...{" "}
                              </p>
                              <NavLink
                                onClick={closeNav}
                                to="/programming-and-data-processing/"
                                className="btn"
                              >
                                {" "}
                                Read More
                              </NavLink>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li>
                    <span className="main-nav-name">
                      <span>Who we are</span>{" "}
                      <FaAngleDown onClick={tabToggle2} />
                    </span>

                    <div className={tab2 ? "drop  open" : "drop"}>
                      <div className="drop-content-wrapper">
                        <ul className="main-page-link-wrapper">
                          <li className="overview about-over">
                            <NavLink onClick={closeNav} to="/about-us">
                              Overview <FaAngleRight />
                            </NavLink>
                            <div className=" sub-drop-item">
                              <h3 className="h4">About Us</h3>
                              <p className="pb-4">
                                At Poll Sphere, we are more than just a market
                                research and online survey company. We are your
                                trusted partner in{" "}
                              </p>
                              <NavLink
                                onClick={closeNav}
                                to="/about-us/"
                                className="btn  "
                              >
                                Join us
                              </NavLink>
                            </div>
                          </li>
                          <li>
                            <NavLink onClick={closeNav} to="/about-us/">
                              About Us{" "}
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li>
                    <NavLink onClick={closeNav} to="/faq/">
                      FAQ
                    </NavLink>
                  </li>

                  <div className="nav-top-mobile">
                    <ul>
                      <li>
                        <NavLink onClick={closeNav} to="/contact-us/">
                          Contact Us
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </ul>
              </div>
            </div>
            <div className="nav-right">
              <ul className="mb-0 mr-3 panal flex-centet-start">
                <li>
                  <NavLink to="/contact-us/">Contact Us</NavLink>
                </li>
              </ul>

              <div className="bar">
                <span onClick={() => navToggle()}>
                  <FaBarsStaggered />
                </span>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
};

export default Header;
