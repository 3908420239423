import React from "react";
import { Col, Row } from "antd";
const PageBanner = ({  image, heading, para, subpara }) => {
  return (
    <div>
    <div className=" page-banner position-relative">
    <div className="image-wrapper">
      <img src={image} alt="" />
    </div>
    <div className="page-banner-content">
      <div className="container h-100">
        <Row className="row d-flex h-100 " align={"middle"}>
          <Col lg={12}>
            <div className="wrapper text-white ">
              <h1>{heading}</h1>
              <p>
            {para}
              </p>
              <p>{subpara}</p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  </div>





    
    </div>
  );
};

export default PageBanner;