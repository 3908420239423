import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Col, Row } from "antd";
import OurVesion from "../components/OurVesion";

const About = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  const currentURL = window.location.href;
const navigate = useNavigate();
useEffect(() => {
  window.scrollTo(0, 0);
}, [pathname]);
useEffect(() => {
  if(!location.pathname.endsWith('/')){
    let NewPath = location.pathname+"/".toLowerCase();
    if (location.pathname !== NewPath) {
        navigate(NewPath);
    }
    }
}, [location, navigate]);
  return (
    <div className="page-content">
      <Helmet>
        <title>About Us | Poll Sphere</title>
        <meta
          name="description"
          content="We're here to help you make sense of the complex data and provide you with valuable insights so that you can make informed decisions. "
        />
        <link rel="canonical" href="https://www.pollsphere.com/about-us/" />
      </Helmet>
      <div className="about-banner ">
        <div className="container">
          <Row align={"middle"}>
            <Col md={8}>
              <div className="about-img">
                <img src="/images/about/banner.jpg" alt="" />
              </div>
            </Col>
            <Col md={4}></Col>
            <Col md={12}>
              <div className="about-banner-content">
                <h1> About Us</h1>
                <p>
                  At Poll Sphere, we are more than just a market research and
                  online survey company. We are your trusted partner in
                  navigating the dynamic landscape of data-driven
                  decision-making. Our journey began with a vision: to empower
                  businesses and organizations with the insights they need to
                  thrive in an ever-changing world. Today, we are proud to have
                  become a leading force in the industry, renowned for our
                  dedication to excellence, innovation, and client satisfaction.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <section className="py-50 bg-even ">
        <div className="container">
          <Row align="middle" className="content-main-wrapper">
            <Col md={12}>
              <div className="content-wrapper px-50">
                <h2> Our Approach</h2>
                <p>
                  At Poll Sphere, we believe that every project is unique. We
                  take a tailored approach to every client, combining
                  cutting-edge technology with the human touch. Our dedicated
                  team of data scientists, market researchers, programmers, and
                  analysts works collaboratively with you to understand your
                  goals, challenges, and aspirations.
                </p>
              </div>
            </Col>
            <Col md={12}>
              <div className="img-wrapper">
                <img src="/images/about/Approach.jpg" alt="" />
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section className="py-50">
        <div className="container snapshot-wrapper">
          <h2 className="heading">Brief Snapshot of Poll Sphere</h2>
          <Row gutter={24}>
            <Col md={6}>
              <div className="box-wrapper">
                <div className="img-wrapper">
                  <img src="/images/about/commitment.jpg" alt="" />
                </div>

                <div className="text">
                  <span>Snapshot</span>
                  <h2>Empowering Insights</h2>
                  <p>
                    Transform data into decisions with user-friendly surveys and
                    actionable analytics.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="box-wrapper box-wrapper2">
                <div className="img-wrapper">
                  <img src="/images/about/context.jpg" alt="" />
                </div>

                <div className="text">
                  <span>Snapshot</span>
                  <h2>Innovative Solutions</h2>
                  <p>
                    Drive progress through democratized access to cutting-edge
                    market research tools.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="box-wrapper box-wrapper3">
                <div className="img-wrapper">
                  <img src="/images/about/beliefs.jpg" alt="" />
                </div>

                <div className="text">
                  <span>Snapshot</span>
                  <h2>Community Hub</h2>
                  <p>
                    Join a collaborative space where diverse perspectives
                    converge for shared success.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="box-wrapper box-wrapper4">
                <div className="img-wrapper">
                  <img src="/images/about/stories.jpg" alt="" />
                </div>

                <div className="text">
                  <span>Snapshot</span>
                  <h2>Ethical Excellence</h2>
                  <p>
                    Championing data privacy, transparency, and integrity in
                    every insight generated.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section className="py-50 our-vesion-wrapper">
        <OurVesion />
      </section>
    </div>
  );
};

export default About;
