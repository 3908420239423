import React, {useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import PageBanner from "../components/PageBanner";
import { Card, Col, Collapse, Row } from 'antd';
const { Panel } = Collapse;

const FAQ = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  const currentURL = window.location.href;
const navigate = useNavigate();
useEffect(() => {
  window.scrollTo(0, 0);
}, [pathname]);
useEffect(() => {
  if(!location.pathname.endsWith('/')){
    let NewPath = location.pathname+"/".toLowerCase();
    if (location.pathname !== NewPath) {
        navigate(NewPath);
    }
    }
}, [location, navigate]);
  const onChange = (key) => {
    console.log(key);
  };
  const bannerData = {
    image: "/images/services/faq-banner.jpg",
    heading: "Frequently Asked Questions",
    para: "  Explore our FAQs for quick answers to common queries about Poll Sphere. From getting started to data security, find the information you need to make the most of your survey and research experience.",
  };
  return (
    <div className="page-content">
    <Helmet>
    <title>Poll Sphere - Frequently Asked Questions</title>
    <meta name="description" content="This section includes a list of questions and answers that are related to the website's features, services, and policies." />
    <link rel="canonical" href="https://www.pollsphere.com/faq/" />
    </Helmet>
    <PageBanner {...bannerData}/>
   
        <div id='faq' className="container our-vesion py-50">
          <div className="heading">
          <span>FAQ</span>
            <h2 className=""><span>Explore Your Answers: Frequently Asked Questions (FAQ) about Poll Sphere</span> </h2>
          
          </div>
          <Row gutter={24}>
            <Col xs={{ span: 24 }} >
              <Card  bordered={false} >


                <Collapse bordered={false}  onChange={onChange} >
                  <Panel className='font16' header="How do I get started with Poll Sphere?" key="1" >
                    <p>Getting started is easy! Simply sign up for a Poll Sphere account, and you can start creating surveys right away. Our intuitive interface makes the process seamless, whether you're a seasoned researcher or a first-time user.</p>
                  </Panel>
                  <Panel className='font16' header="What types of surveys can I create on Poll Sphere?" key="2">
                    <p>Poll Sphere offers a variety of survey types, including multiple-choice, open-ended, rating scales, and more. Customize your surveys to suit your specific needs and gather the insights that matter most to you.</p>
                  </Panel>
                  <Panel className='font16' header="How can I analyze the results of my surveys?" key="3">
                    <p>Once your survey is complete, access comprehensive analytics tools on Poll Sphere. Our platform provides real-time reporting and data visualization, allowing you to interpret results with ease.</p>
                  </Panel>
                  <Panel className='font16' header="Is my data secure on Poll Sphere?" key="4">
                  <p>Absolutely. We take data security seriously. Poll Sphere employs robust measures to protect your data, ensuring it remains confidential and in compliance with industry standards.</p>
                </Panel>


                <Panel className='font16' header="Can I collaborate with others on survey projects?" key="5" >
                <p>Yes, collaboration is at the heart of Poll Sphere. Invite team members to collaborate on survey creation and data analysis, fostering a collaborative environment for more impactful insights.</p>
              </Panel>
              <Panel className='font16' header="Are there templates available for common survey types?" key="6">
                <p>Certainly! Poll Sphere offers a range of survey templates to kickstart your projects. Choose from templates tailored for customer feedback, employee engagement, market research, and more.</p>
              </Panel>
              <Panel className='font16' header="How do I reach customer support?" key="7">
                <p>Our customer support team is here to assist you. You can reach us through the "Contact Us" page on our website, where you'll find multiple channels, including email and live chat.</p>
              </Panel>
              <Panel className='font16' header="What industries can benefit from Poll Sphere?" key="8">
              <p>Poll Sphere is versatile and caters to a wide range of industries. Whether you're in healthcare, education, retail, or any other sector, our platform provides valuable insights for informed decision-making.</p>
            </Panel>
            <Panel className='font16' header="Can I export survey data for further analysis?
            " key="7">
                <p> Absolutely! Poll Sphere allows you to export survey data in various formats, such as Excel or CSV, giving you the flexibility to conduct further analysis using your preferred tools.</p>
              </Panel>
              <Panel className='font16' header="How often are new features added to Poll Sphere?" key="8">
              <p>We are committed to continuous improvement. New features and updates are regularly rolled out to enhance your Poll Sphere experience. Stay tuned for exciting advancements that elevate your survey and research capabilities.</p>
            </Panel>
                 
                </Collapse>
                {/* </div> */}
              </Card>
            


            </Col>
          


          </Row>
        </div>
    

        </div>

  );
};
export default FAQ;